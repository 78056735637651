import React from 'react'
import { connect } from 'react-redux'
import i18next from 'i18next'
import { Button, Modal, Table, Form, Col } from 'react-bootstrap'
import { Trans } from 'react-i18next'
import ProgressBar from 'react-bootstrap/ProgressBar'

import StyledSelect from 'components/system_wide/styled_select'
import { getCondominiumContracts, requestCondominiumContracts } from 'store/condominia'
import { addNewSupplier } from 'store/contracts'
import { getSuppliers } from 'store/contracts'
import { isAdmin as _isAdmin } from 'lib/utils'
import { error } from 'components/system_wide/notification'

const { t } = i18next

const mstp = state => {
  let { suppliers } = state.contracts
  let { contracts } = state.condominia.edit_condominia
  let { progress, condominia } = state.condominia
  let { role_id } = state.userInfo
  let { roles } = state.usersData
  let { strings: trans } = state.translations
  let isAdmin = _isAdmin(role_id, roles)
  contracts = contracts?.reduce((acc, ct) => {
    return {
      ...acc,
      [parseInt(ct.service.id)]: {
        id: ct.id,
        expire_at: ct.expire_at,
        service_option_id: ct.service_option_id,
        new: false,
        supplier_id: ct.supplier_id,
      },
    }
  }, {})
  return { condominia, contracts, isAdmin, trans, suppliers, progress }
}

class ManageServiceModal extends React.Component {
  state = {
    contracts: {},
    suppliers: [],
    show_new_supplier: false,
    selected_service_id: null,
    supplier_name: '',
    error_supplier_name: false,
    validated: false,
  }
  static defaultProps = {
    servicesOpts: [],
    selected_condominia: [],
  }

  parentRef = React.createRef()

  selectService = svid => () => {
    let contracts = this.state.contracts
    let suppliers = this.props.suppliers
    let { selected_condominia } = this.props
    let conhive = suppliers.find(s => s.name === 'Conhive')
    if (contracts[svid]?.deleted === true) {
      delete contracts[svid].deleted
      selected_condominia.forEach(cid => {
        if (!contracts[svid].condominium_contract[cid]) {
          contracts[svid].condominium_contract[cid] = null
        }
      })
    } else {
      contracts[svid] = {
        id: Math.floor(Math.random() * 1000),
        expire_at: '',
        service_option_id: '',
        new: true,
        supplier_id: this.props.isAdmin ? conhive.id : '',
      }
    }
    this.setState({ contracts })
  }
  removeService = svid => () => {
    let contracts = this.state.contracts
    contracts[svid].deleted = true
    if (contracts[svid].new === true) delete contracts[svid]
    this.setState({ contracts })
  }

  componentDidMount = async () => {
    await this.props.dispatch(getSuppliers())
    if (this.props.selected_condominia.length === 1) {
      await this.props.dispatch(getCondominiumContracts(this.props.selected_condominia[0]))
    } else {
      let contracts = {}
      let all_contracts = []
      for (let c_id of this.props.selected_condominia) {
        let c_contracts = await requestCondominiumContracts(c_id)
        all_contracts = [...all_contracts, ...c_contracts]
      }

      contracts = all_contracts?.reduce((acc, ct) => {
        let expire_at = ct.expire_at
        let service_option_id = ct.service_option_id
        let supplier_id = ct.supplier_id
        let condominium_contract = { [ct.condominium_id]: ct.id }

        // se ha trovato il contratto in un altro condominio resetto i valori se questi sono diversi
        let service = acc?.[ct.service.id] ?? {}
        if (Object.keys(service).length > 0) {
          expire_at = expire_at !== service.expire_at ? '' : expire_at
          supplier_id = supplier_id !== service.supplier_id ? null : supplier_id
          condominium_contract = { ...service.condominium_contract, ...condominium_contract }
        }
        return {
          ...acc,
          [ct.service.id]: {
            expire_at,
            service_option_id,
            new: false,
            supplier_id,
            condominium_contract,
          },
        }
      }, {})
      this.setState({ contracts })
    }
  }
  componentDidUpdate = prevProps => {
    if (this.props.contracts !== prevProps.contracts) {
      this.setState({ contracts: { ...this.props.contracts } })
    }
  }

  onChangeExpireAt = svid => e => {
    let contracts = this.state.contracts
    contracts[svid].expire_at = e.target.value
    this.setState({ contracts })
  }
  handleServiceOptionChange = svid => e => {
    let value = e?.value || ''
    let contracts = this.state.contracts
    contracts[svid].service_option_id = value
    this.setState({ contracts })
  }
  handleSupplierChange =
    svid =>
    ({ value }) => {
      let contracts = { ...this.state.contracts }
      contracts[svid].supplier_id = value
      this.setState({ contracts })
    }

  validateState = () => {
    let { contracts } = this.state
    return Object.keys(contracts).reduce((acc, el) => acc && contracts[el].service_option_id !== '', true)
  }

  handleConfirm = () => {
    let valid = this.validateState()
    this.setState({ validated: true })
    if (valid) {
      this.props.onChangeServices(this.state.contracts)
    }
  }

  showNewSupplier = selected_service_id => () =>
    this.setState({ show_new_supplier: true, selected_service_id, supplier_name: '' })
  closeNewSupplier = () => {
    this.setState({
      show_new_supplier: false,
      selected_service_id: null,
      supplier_name: '',
      error_supplier_name: false,
    })
  }
  handleChangeSupplierName = e => this.setState({ supplier_name: e.target.value, error_supplier_name: false })
  onAddNewSupplier = async () => {
    if (this.state.supplier_name !== '') {
      let new_supplier_id = await this.props.dispatch(addNewSupplier(this.state.supplier_name))
      let contracts = { ...this.state.contracts }
      contracts[this.state.selected_service_id].supplier_id = new_supplier_id.payload
      await this.props.dispatch(getSuppliers())
      this.setState({ contracts })
      this.closeNewSupplier()
    } else {
      this.setState({ error_supplier_name: true })
      error(t('contracts.add_supplier.empty_name', 'Il campo nome non può essere vuoto!'))
    }
  }

  renderCheckbox = (services_ids, s_id) => {
    let { selected_condominia } = this.props
    let { contracts } = this.state
    let contractsLenght = Object.keys(contracts?.[s_id]?.condominium_contract ?? {}).length
    if (contracts[s_id]?.deleted === true) {
      return <i className="far fa-square text-muted" />
    }
    if (
      Object.keys(contracts).includes(s_id) &&
      (selected_condominia.length === contractsLenght || contractsLenght === 0)
    ) {
      return <i className="fas fa-check-square text-primary" />
    } else if (services_ids.includes(s_id) && contractsLenght !== selected_condominia.length) {
      return <i className="fas fa-square" style={{ color: 'lightblue' }} />
    } else {
      return <i className="far fa-square text-muted" />
    }
  }

  divRef = React.createRef()
  onScroll = () => {
    let parentRect = this.divRef.current?.getBoundingClientRect() ?? null
    this.setState({ parentRect })
  }

  render() {
    let { servicesOpts, isAdmin, trans, suppliers, progress, condominia, selected_condominia } = this.props
    let { contracts = {}, show_new_supplier, supplier_name, error_supplier_name, parentRect, validated } = this.state
    let { services: tServices, service_options: tServiceOptions } = trans?.tables ?? {}

    let services_ids = condominia
      .filter(c => selected_condominia.includes(c.id))
      .reduce((acc, c) => [...new Set([...acc, ...(c.services?.map(s => s.id) ?? [])])], [])

    let suppliersOpts = suppliers
      .map(ss => ({
        value: ss.id,
        label: ss.name,
      }))
      .filter(ss => {
        if (!isAdmin) return ss.label !== 'Conhive'
        else return ss
      })
    let conhive_supplier = suppliers.find(el => el.name === 'Conhive')
    return (
      <>
        <Modal
          size="lg"
          backdrop="static"
          centered
          show={true}
          onHide={this.props.onToggleMngServices}
          style={show_new_supplier ? { filter: 'blur(2px)' } : {}}
          className="menu-dialog">
          <Modal.Header className="pb-0" closeButton>
            <Modal.Title>Associa servizi</Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex flex-row">
            <div
              ref={this.divRef}
              className="flex-fill pe-2 mt-2 overflow-auto position-relative container"
              style={{ maxHeight: '30rem' }}
              onScroll={this.onScroll}>
              <Table responsive bordered hover size="sm" style={{}} ret={this.parentRef}>
                <thead style={{ height: '3rem', verticalAlign: 'middle', position: 'sticky' }}>
                  <tr>
                    <th style={{ textAlign: 'center' }}>#</th>
                    <th style={{ textAlign: 'center' }}></th>
                    <th className="ps-3">Servizio</th>
                    <th className="ps-2">Service Option</th>
                    <th className="ps-2">Fornitore</th>
                    <th className="ps-2">Data scadenza</th>
                  </tr>
                </thead>
                <tbody>
                  {servicesOpts.map(s => {
                    let sv_selected = Object.keys(contracts).includes(s.value)
                    if (contracts[s.value]?.deleted === true) sv_selected = false
                    let tr_bg = sv_selected ? '#fcf7b6' : ''
                    let so_selected =
                      sv_selected === true &&
                      s?.service_options
                        .filter(sv => contracts[s.value]?.service_option_id == parseInt(sv.value))
                        .map(so => ({ value: so.value, label: tServiceOptions[so.label] ?? so.label }))
                    let supplier =
                      sv_selected === true &&
                      suppliers
                        .filter(sp => {
                          return sp.id === contracts?.[s.value]?.supplier_id?.toString()
                        })
                        .map(sp => ({ value: sp.id, label: sp.name }))
                    let isConhiveSupplier =
                      parseInt(this.props.contracts?.[s.value]?.supplier_id) === parseInt(conhive_supplier?.id)

                    return (
                      <tr key={s.value} style={{ height: '3rem', verticalAlign: 'middle', background: tr_bg }}>
                        <td
                          style={{ textAlign: 'center' }}
                          onClick={sv_selected ? this.removeService(s.value) : this.selectService(s.value)}>
                          {this.renderCheckbox(services_ids, s.value)}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <i className={`text-muted ${s.icon_classes}`} />
                        </td>
                        <td className="ps-3">{tServices[s.codename] ?? s.label}</td>
                        <td className="ps-2 pe-2">
                          {s.service_options.length > 0 && (
                            <StyledSelect
                              isClearable
                              placeholder={t('condominia.select_option_placeholder', 'Seleziona opzione..')}
                              value={so_selected}
                              options={s.service_options.map(so => ({
                                value: so.value,
                                label: tServiceOptions[so.label] ?? so.label,
                              }))}
                              onChange={this.handleServiceOptionChange(s.value)}
                              isDisabled={(!isAdmin && isConhiveSupplier) || !sv_selected}
                              parentRef={this.parentRef}
                              valid={(sv_selected && validated && so_selected.length > 0) || !sv_selected || !validated}
                            />
                          )}
                        </td>
                        <td className="ps-2 pe-2 d-flex flex-row">
                          <StyledSelect
                            placeholder={t('condominia.select_option_placeholder', 'Seleziona fornitore..')}
                            value={supplier}
                            options={suppliersOpts}
                            onChange={this.handleSupplierChange(s.value)}
                            isDisabled={(!isAdmin && isConhiveSupplier) || !sv_selected}
                            className="flex-fill me-2"
                            parentRect={parentRect}
                          />
                          <Button
                            disabled={!isAdmin && isConhiveSupplier}
                            onClick={this.showNewSupplier(s.value)}
                            variant="primary"
                            className="me-auto"
                            size="sm">
                            <i className="fa fa-plus" />
                          </Button>
                        </td>
                        <td className="ps-2 pe-2">
                          <Form.Group controlId="dob">
                            <Form.Control
                              type="date"
                              name="expire_at"
                              value={contracts[s.value]?.expire_at?.split('T')[0] ?? ''}
                              disabled={!sv_selected}
                              onChange={this.onChangeExpireAt(s.value)}
                            />
                          </Form.Group>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.props.onToggleMngServices} variant="secondary" className="me-auto">
              <Trans i18nKey="common.cancel_button">Annulla</Trans>
            </Button>
            {progress > -1 && (
              <Col className="flex-fill ms-3 me-3">
                <ProgressBar animated={true} now={progress} />
              </Col>
            )}
            <Button onClick={this.handleConfirm} variant="primary">
              <Trans i18nKey="condominia.mng_service_modal.edit_button">Salva</Trans>
            </Button>
          </Modal.Footer>
        </Modal>
        {show_new_supplier === true && (
          <Modal size="md" backdrop="static" centered show={show_new_supplier} onHide={this.closeNewSupplier}>
            <Modal.Header className="pb-0" closeButton>
              <Modal.Title>Aggiungi fornitore</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex flex-row">
              <Form.Control
                name="supplier_name"
                placeholder={t('assignments.condominia.form.name', 'Nome')}
                value={supplier_name}
                onChange={this.handleChangeSupplierName}
                isInvalid={error_supplier_name}
              />
              <Button onClick={this.onAddNewSupplier} variant="primary" className="ms-3 d-flex align-items-center">
                <i className="fa fa-plus me-2" />
                Aggiungi
              </Button>
            </Modal.Body>
          </Modal>
        )}
      </>
    )
  }
}

export default connect(mstp)(ManageServiceModal)
