import React from 'react'
import { Trans } from 'react-i18next'
import { Button, Modal, Table } from 'react-bootstrap'
import DownloadContractButton from 'components/system_wide/download_contract_button'
import { renderExpireAt } from 'components/system_wide/utils'
import 'scss/contracts.css'

class ModalContract extends React.Component {
  render() {
    let { show_modal, contract, tServices, suppliers } = this.props
    let supplier = suppliers.find(s => parseInt(s.id) === parseInt(contract?.supplier_id ?? 0))
    let building_administrator =
      contract.condominium.unassigned === true
        ? 'CONDOMINIO CEDUTO'
        : contract?.user?.tax_datum?.business_name ?? `${contract?.user?.name} ${contract?.user?.surname}`
    return (
      <Modal backdrop="static" centered show={show_modal} onHide={this.props.onCloseModal} size="lg">
        <Modal.Header className="pb-0" closeButton>
          <Modal.Title>
            <h4 className="text-primary p-1">
              <Trans i18nKey="contracts.modal_title">Contratto</Trans>
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column">
          <div className="row">
            <div className="col-6">
              <div className="text-muted modal-title">Amministratore</div>
              <div
                className={`d-flex flex-row align-items-center ${contract.condominium?.unassigned === true ? 'text-warning fw-bold' : ''}`}>
                {contract?.user?.deactivated === true && (
                  <i className="fa fa-ban me-3 text-warning " aria-hidden="true" title="Disabilitato" />
                )}
                <div>{building_administrator}</div>
              </div>
            </div>
            <div className="col-6">
              <div className="text-muted modal-title">Condominio</div>
              <div>{contract?.condominium?.name}</div>
              <div>{contract?.condominium?.street}</div>
            </div>
          </div>
          <div className="row border-top mt-3 pt-2">
            <h5>Servizio attivo</h5>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              <div className="d-flex align-items-center">
                <i className={contract?.service?.icon_classes} />
                <div className="ps-3">{tServices[contract?.service?.codename] ?? contract?.service?.description}</div>
              </div>
              <div className="d-flex align-items-center" style={{ marginLeft: '2rem' }}>
                <span className="text-muted pe-1">Fornitore: </span>
                {supplier?.name ?? '-'}
              </div>
            </div>
            <div className="col-6">
              <div>
                <span className="text-muted">Prezzo:</span> {contract.price} €
              </div>
              <div className="d-flex">
                <span className="text-muted pe-1">Data di scadenza: </span> {renderExpireAt(contract.expire_at)}
              </div>
            </div>
          </div>
          <div className="row border-top mt-3 pt-2">
            <h5>Documenti</h5>
          </div>
          <div className="overflow-auto" style={{ height: '15rem' }}>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>File</th>
                  <th className="text-center" style={{ width: '2rem' }}>
                    <Trans i18nKey="actions_dropdown.title.download">Scarica</Trans>
                  </th>
                </tr>
              </thead>
              <tbody>
                {(contract.attachments ?? []).map(dw => (
                  <tr style={{ height: '1rem' }} key={`dw-${dw.id}`}>
                    <td style={{ maxWidth: '10rem', wordWrap: 'break-word' }}>{dw.name}</td>
                    <td>
                      <DownloadContractButton
                        contractId={this.props.contract.id}
                        attachmentsId={dw.id}
                        fileName={dw.name}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onCloseModal} variant="secondary">
            <Trans i18nKey="common.close_button">Chiudi</Trans>
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ModalContract
